export const theme = {
	palette: {
		background: {
			default: "#ffffff"
		},
		primary: {
			light: "#E62850",
			main: "#E62850",
			dark: "#E62850"
		},
		secondary: {
			light: "#E62850",
			main: "#E62850",
			dark: "#E62850"
		},
		text: {
			primary: "#41352C",
			secondary: "#988B81"
		}
	}
};
