import React, { Suspense } from "react";
import loadable from "@loadable/component";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import createTheme from "@material-ui/core/styles/createTheme";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import { StylesProvider } from "@material-ui/core/styles";
import Ui from "@micado-digital/react-ui/components/Ui";
import { theme as customTheme } from "./themes/project";

const Concierge = loadable(() => import("./lib/components/Concierge"));
const Ratings = loadable(() => import("./lib/components/Ratings"));
const VideoPlayer = loadable(() => import("./lib/components/VideoPlayer"));

const theme = createTheme(customTheme);

const $concierge = document.querySelectorAll("#concierge-app");
const $videoPlayer = document.querySelectorAll("#video-player-app");
const $ratings = document.querySelectorAll("#react-rating-detail");

if ($ratings) {
	$ratings?.forEach(element => {
		const headlineCategories = element?.getAttribute("data-headline-categories");
		const headlineSources = element?.getAttribute("data-headline-sources");

		ReactDOM.render(
			<ThemeProvider theme={theme}>
				<StylesProvider>
					<Suspense fallback={<></>}>
						<Ui>
							<Ratings
								headlineCategories={headlineCategories}
								headlineSources={headlineSources}
							/>
						</Ui>
					</Suspense>
				</StylesProvider>
			</ThemeProvider>,
			element
		);
	});
}

if ($concierge) {
	$concierge.forEach(element => {
		const additionalParams = element?.getAttribute("data-params");
		const api = element?.getAttribute("data-api");
		const apiKey = element?.getAttribute("data-key");
		const headline = element?.getAttribute("data-headline");
		const placeholder = element?.getAttribute("data-placeholder");
		const placeholderNext = element?.getAttribute("data-placeholder-next");
		const showWelcome = element?.getAttribute("data-show-welcome");
		const welcomeAPI = element?.getAttribute("data-welcome-api");

		ReactDOM.render(
			<ThemeProvider theme={theme}>
				<StylesProvider>
					<Suspense fallback={<></>}>
						<BrowserRouter>
							<Ui>
								<Routes>
									<Route
										path="/*"
										element={
											<Concierge
												additionalParams={additionalParams}
												api={api}
												apiKey={apiKey}
												headline={headline}
												inputPlaceholder={placeholder}
												inputPlaceholderNext={placeholderNext}
												showWelcome={showWelcome === "true" ? true : false}
												welcomeAPI={welcomeAPI}
											/>
										}
									/>
								</Routes>
							</Ui>
						</BrowserRouter>
					</Suspense>
				</StylesProvider>
			</ThemeProvider>,
			element
		);
	});
}

if ($videoPlayer) {
	$videoPlayer?.forEach(element => {
		const defaultSeason = element?.getAttribute("data-season");
		const folder = element?.getAttribute("data-folder");
		const lang = element?.getAttribute("data-lang");

		ReactDOM.render(
			<ThemeProvider theme={theme}>
				<StylesProvider>
					<Suspense fallback={<></>}>
						<BrowserRouter>
							<Ui>
								<Routes>
									<Route
										path="/*"
										element={
											<VideoPlayer defaultSeason={defaultSeason} folder={folder} lang={lang} />
										}
									/>
								</Routes>
							</Ui>
						</BrowserRouter>
					</Suspense>
				</StylesProvider>
			</ThemeProvider>,
			element
		);
	});
}
